import {
  forwardRef,
  type ComponentProps,
  type ForwardRefRenderFunction,
} from 'react';

import {
  type TamagoshiIconComponent,
  type TamagoshiLogoComponent,
} from '@/core/tamagoshiTailwind/interfaces';

export function makeTamagoshiIcon(
  arg: ForwardRefRenderFunction<
    SVGSVGElement,
    ComponentProps<TamagoshiIconComponent>
  >,
) {
  return forwardRef(arg) as unknown as TamagoshiIconComponent;
}

export function makeTamagoshiLogo(
  arg: ForwardRefRenderFunction<
    SVGSVGElement,
    ComponentProps<TamagoshiLogoComponent>
  >,
) {
  return forwardRef(arg) as unknown as TamagoshiLogoComponent;
}
