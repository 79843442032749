import { translate, translateFormatter, type RichTextFormatter } from '@/domains/core/localization/translate';
export const {
  sponsorBrandInfoTitle
} = translate({
  "sponsorBrandInfoTitle": "Informaci\xF3n"
});
export const sponsorBrandInfoDescription = translateFormatter<{
  brand: string;
}>("Contenido patrocinado por la marca {brand}", undefined, undefined, "es");
export const {
  sponsorInfoTitle
} = translate({
  "sponsorInfoTitle": "Informaci\xF3n sobre un enlace patrocinado"
});
export const sponsorInfoModalDescription = translateFormatter("Productos patrocinados como por un servicio opcional de pago suscrito por el Vendedor. <a>Conoce m\xE1s sobre productos patrocinados</a>. Este anuncio se muestra en funci\xF3n de tus t\xE9rminos de b\xFAsqueda o productos vistos en esta p\xE1gina.", undefined, undefined, "es");
export const sponsorInfoDescription = translateFormatter("Producto patrocinado como por un servicio opcional de pago suscrito por el Vendedor. <a>Conoce m\xE1s sobre productos patrocinados</a>. Este anuncio se muestra en funci\xF3n de tus t\xE9rminos de b\xFAsqueda o productos vistos en esta p\xE1gina.", undefined, undefined, "es");
export const {
  knowMoreLink
} = translate({
  "knowMoreLink": "https://cdn.manomano.com/legal/consumer-information/es.pdf"
});
export const advertiserName = translateFormatter<{
  name: string;
  strong: RichTextFormatter;
}>("<strong>Nombre del anunciante:</strong> {name}", undefined, undefined, "es");
export const advertiserPayerName = translateFormatter<{
  name: string;
  strong: RichTextFormatter;
}>("<strong>\xBFQui\xE9n paga por este anuncio?</strong> {name}", undefined, undefined, "es");