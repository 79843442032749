import { Svg } from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';

import { makeTamagoshiIcon } from '@/core/tamagoshiTailwind/util/makeTamagoshiComponent';

export const TrendDownIcon = makeTamagoshiIcon((props, ref) => (
  <Svg
    optimizationId="iconsSystemTrendDown"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    ref={ref}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.29289 5.29289C1.68342 4.90237 2.31658 4.90237 2.70711 5.29289L9 11.5858L11.9393 8.64645C12.5251 8.06066 13.4749 8.06066 14.0607 8.64644L13.3764 9.33074L13.3536 9.35355M13.3764 9.33074L14.0607 8.64644L21 15.5858V12C21 11.4477 21.4477 11 22 11C22.5523 11 23 11.4477 23 12V17.5C23 18.3284 22.3284 19 21.5 19H16C15.4477 19 15 18.5523 15 18C15 17.4477 15.4477 17 16 17H19.5858L13 10.4142L10.0607 13.3536C9.47487 13.9393 8.52513 13.9393 7.93934 13.3536L1.29289 6.70711C0.902369 6.31658 0.902369 5.68342 1.29289 5.29289"
      fill="currentColor"
    />
  </Svg>
));

TrendDownIcon.displayName = 'TrendDownIcon';
